import useTranslation from 'next-translate/useTranslation'
import { FC, MouseEvent, ReactNode } from 'react'
import { Button, FlexboxGrid } from 'rsuite'
import { useRouter } from 'next/router'

import { Catalog, Product } from '../utils/types/Product'
import contactEvent from '../utils/analytics/contactEvent'
import useContactModal from './Modals/hooks/useContactModal'
import { getCountryAndLocaleStrings } from '../utils/locales'
import { getStrapiMediaUrl } from '../utils/strapi'
import { AnalyticsLocation, AnalyticsSubLocation, ContactContext } from '../utils/types/analytics'
import useSampleRequestModal from './Modals/hooks/useSampleRequestModal'
import useGetAnalyticsLocation from '../services/analytics/useGetAnalyticsLocation'
import Avatar from './Avatar/Avatar'
import useProductSpecialistByCategory from '../services/strapi/useProductSpecialistByCategory'

import styles from '../styles/ContactCard.module.less'
import buttonStyles from '../styles/CustomButtons.module.less'

interface ContactCardProps {
  hasPhone?: boolean
  hasChatButton?: boolean
  hasSampleRequestButton?: boolean
  hasContactModalButton?: boolean
  product?: Product
  categories?: Catalog[]
  className?: string
  customTextContent?: ReactNode
  analyticsContactContext?: ContactContext
  analyticsLocation?: AnalyticsLocation
}

const ContactCard: FC<ContactCardProps> = (props) => {
  const {
    hasPhone,
    hasChatButton,
    hasSampleRequestButton,
    hasContactModalButton,
    product,
    categories,
    className,
    customTextContent,
    analyticsContactContext,
    analyticsLocation,
  } = props

  const { t } = useTranslation('modals')
  const { locale: countryAndLocale } = useRouter()

  const analyticsDefaultLocation = useGetAnalyticsLocation(AnalyticsSubLocation.ProductSpecialist)
  const analyticsDefaultContactContext = analyticsContactContext || 'product_specialist'

  const openSampleRequestModal = useSampleRequestModal({
    analyticsLocation: analyticsLocation || analyticsDefaultLocation,
    product,
  })
  const openContactModal = useContactModal(
    analyticsDefaultContactContext,
    analyticsLocation || analyticsDefaultLocation,
  )

  const productSpecialist = useProductSpecialistByCategory(categories)
  const productSpecialistImagePath = getStrapiMediaUrl(
    productSpecialist?.attributes?.profilePicture,
  )
  const productSpecialistName = productSpecialist?.attributes?.name || '---'
  const productSpecialistPhone = productSpecialist?.attributes?.phone || ''

  const { country } = getCountryAndLocaleStrings(countryAndLocale)
  const countryLocale = (country ?? 'fi')

  // Checks wether number has country code and formats it for href attribute accordingly
  const formatPhoneLink = (phone: string) => {
    const phoneRegex = new RegExp('^[+][0-9 ]+$')
    const hasCountryCode = phoneRegex.test(phone)
    const formattedPhone = phone.replace(/\s/g, '') // Remove spaces

    if (hasCountryCode) return formattedPhone

    switch (countryLocale) {
      case 'fi':
        return formattedPhone.replace(phone[0], '+358')
      case 'sv':
        return formattedPhone.replace(phone[0], '+46')
      case 'de':
        return formattedPhone.replace(phone[0], '+49')
      default:
        return formattedPhone
    }
  }

  const onChatClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!window.chaport) return
    window.chaport.q('open')
    // TRACKING-EVENT: Contact event
    contactEvent(analyticsDefaultContactContext)
  }

  return (
    <>
      <FlexboxGrid
        className={`${styles['contact-card']} ${className || ''} margin-top-spacer-tripple flex-flow-row`}
        data-testid="contact-card"
      >
        <FlexboxGrid.Item className="margin-right-spacer-double">
          <Avatar
            imageSrc={productSpecialistImagePath ?? ''}
            altName={`${t('Product expert')} / ${productSpecialistName}`}
            size="lg"
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          as="ul"
          colspan={customTextContent ? 18 : undefined}
          className={`padding-left-spacer-double ${styles['cta-wrapper']} flex-shrink-col-dynamic`}
        >
          <li>
            <p>
              <b>
                {t('Customer service')}
                {' '}
                <span
                  data-testid="product-specialist-name"
                  className={styles['product-specialist-name']}
                >
                  /
                  {' '}
                  {productSpecialistName}
                </span>
              </b>
            </p>
          </li>

          {customTextContent && (
            <li>
              <p>
                {customTextContent}
              </p>
            </li>
          )}

          {hasPhone && (
            <li>
              <p>
                {t('Call')}
                {' '}
                <b>
                  <a
                    href={`tel:${formatPhoneLink(productSpecialistPhone)}`}
                          // TRACKING-EVENT: Contact event
                    onClick={() => contactEvent(analyticsDefaultContactContext)}
                  >
                    {productSpecialistPhone ?? '+000 00 000 0000'}
                  </a>
                </b>
              </p>
            </li>
          )}

          {hasContactModalButton && (
            <li>
              <Button
                data-testid="open-contact-card-modal"
                className={`${styles['contact-us-button']}
                            ${buttonStyles['custom-button']}
                            ${buttonStyles['button-caret']}
                            ${buttonStyles['button-dim']}
                            `}
                type="button"
                onClick={() => openContactModal()}
              >
                <b>{t('common:Contact us')}</b>
              </Button>
            </li>
          )}

          {hasSampleRequestButton && (
            <li>
              <Button
                appearance="ghost"
                className={`${styles['product-specialist-cta-button']}
                            ${styles['on-spp']}`}
                onClick={() => openSampleRequestModal()}
              >
                {t('products:Request sample')}
              </Button>
            </li>
          )}

          {hasChatButton && (
            <li>
              <Button
                className={`${styles['chat-button']}
                            ${buttonStyles['custom-button']}
                            ${buttonStyles['button-caret']}
                            ${buttonStyles['button-tertiary']}
                            `}
                type="button"
                onClick={onChatClick}
              >
                <b>{ t('Ask in chat')}</b>
              </Button>
            </li>
          )}

        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  )
}

export default ContactCard
